<template>
  <div class="input-container">
    <label
      v-if="label"
      class="input__label"
      :class="requiredInput && 'input__label-required'"
      >{{ label }}</label
    >
    <div
      class="input__control"
      :class="[size, { error, disabled }, customContainerClass]"
    >
      <input
        ref="hccMoney"
        v-bind="$attrs"
        :class="customClass"
        class="input text-right"
        :type="inputType"
        :disabled="disabled"
        :value="value"
        v-currency="{currency: 'USD', locale: 'en'}"
        v-on="{
          ...$listeners,
          input: (event) => $emit('input', event.target.value),
        }"
      />

      <div v-if="error" class="icon error"><alert-circle /></div>
      <div v-else-if="!!$slots['icon'] && type !== 'password'" class="icon">
        <slot name="icon"></slot>
      </div>
      <button
        class="icon-password"
        v-else-if="type === 'password' && !showPassword"
        @click="toggleShowPassword"
      >
        <eye-off-icon />
      </button>
    </div>
    <div v-if="error && errorMessage" class="input__error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { CurrencyDirective } from 'vue-currency-input';

export default {
  directives: {
    currency: CurrencyDirective,
  },
  data() {
    return {
      showPassword: true,
      inputType: this.type,
    };
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    customClass: {
      type: [String, Object, Array],
    },
    customContainerClass: {
      type: [String, Object, Array],
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    focus() {
      this.$refs.hccInput.focus();
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
      this.inputType = this.showPassword ? 'password' : 'text';
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/inputs.scss";
</style>
